/* eslint-disable prettier/prettier */

export const getLearnTraining = /* GraphQL */ `
  query getLearnTraining($id: String!) {
    getLearnTraining(id: $id) {
      id
      provider
      status
      type
      versions {
        createdAt
        description
        isLatest
        link
        title
        version
      }
    }
  }
`;

export const getLearnUserId = /* GraphQL */ `
  query getLearnUserId($alias: String!) {
    getLearnUserId(alias: $alias) {
      userId
    }
  }
`;

export const listTranscripts = /* GraphQL */ `
  query listTranscripts($learnUserId: ID!, $trainingIds: [String!]) {
    listLearnAndManagedTranscripts(learnUserId: $learnUserId, trainingIds: $trainingIds) {
      id
      completedAt
      failedAt
      status
      trainingId
      trainingVersion
    }
  }
`;
